import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AuthorService {
  API_URL="https://aon-api.tim-host.com/";
  path_usercount="countuser";
  constructor(private httpsClient: HttpClient) { }
  
  getAuthUser(){
    return this.httpsClient.get(this.API_URL+this.path_usercount);
  }

  login(username: string, password: string): Observable<any> {
    return this.httpsClient.post(
      this.API_URL + 'userAuth',
      {
        username,
        password,
      },
      httpOptions, 
    );
    // return console.log('taeee');
  }

}
