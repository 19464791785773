import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AdminDashboardService {
  API_URL="https://aon-api.tim-host.com/";
  path_usercount="countuser";
  path_userList="user";
  path_attthisthay="attthisday";
  path_countattthisthay="countattthisday";
  path_countattthisthayOT="countattthisdayOT";
  path_countattthisthayLT="countattthisdayLT";

  // API_URL="https://api.brilleightyeight.com/Api_Office/index.php?db_akses=1&akses_token=77887788&Store_Code=B8801";
  constructor(private httpsClient: HttpClient) { }
  getUserCount(){
    return this.httpsClient.get(this.API_URL+this.path_usercount);
  }
  getUserList(){
    return this.httpsClient.get(this.API_URL+this.path_userList);
  }
  getAttThisDay(params:any) : Observable<any>{
    return this.httpsClient.get<any>(this.API_URL+this.path_attthisthay);
  }
  getCountAttThisDay(){
    return this.httpsClient.get(this.API_URL+this.path_countattthisthay);
  }
  getCountAttThisDayLT(){
    return this.httpsClient.get(this.API_URL+this.path_countattthisthayLT);
  }
  getCountAttThisDayOT(){
    return this.httpsClient.get(this.API_URL+this.path_countattthisthayOT);
  }
  getAll(params: any): Observable<any> {
    return this.httpsClient.get<any>(this.API_URL, { params });
  }
}