import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <b><i class="fas fa-clock"></i> A O N </b> 2023
    </span>
    <div class="socials">
      <span class="small ion ion-ios-bookmarks-outline"> Version.1.1</span>
    </div>
  `,
})
export class FooterComponent {
}
