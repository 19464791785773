import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AONLocService{
  API_URL="https://aon-api.tim-host.com/";
  image_url="https://tim-host.com/public/storage/profile/";

  path_loclist="locationslist";

  constructor(private httpsClient: HttpClient) { }
  
  getLocationsList(params:any) : Observable<any>{
    return this.httpsClient.get<any>(this.API_URL+this.path_loclist);
  }

}
