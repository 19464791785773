import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class EmpUserService {
  API_URL="https://aon-api.tim-host.com/";
  goole_api_geoloc="https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyALijwsVI4pcflEMD1-gJ2o7wHDxmPP3tc";
  image_url="https://tim-host.com/public/storage/profile/";

  path_usercount="countuser";
  path_userListMod="userMod";
  path_userListPeg="userPeg";
  path_userDetails="user/";

  constructor(private httpsClient: HttpClient) { }
  getUserCount(){
    return this.httpsClient.get(this.API_URL+this.path_usercount);
  }

  getUserListMod(params:any) : Observable<any>{
    return this.httpsClient.get<any>(this.API_URL+this.path_userListMod);
  }
  getUserListPeg(params:any) : Observable<any>{
    return this.httpsClient.get<any>(this.API_URL+this.path_userListPeg);
  }
  
  getUserDetails(params:any) : Observable<any>{
    return this.httpsClient.get<any>(this.API_URL+this.path_userDetails+params);
  }

  getGeol(params:any) : Observable<any>{
    return this.httpsClient.post(this.goole_api_geoloc,"Content-Type: application/json");
  }

}
