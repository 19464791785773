// import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiService {
  API_URL="https://aon-api.tim-host.com/countuser";
  // path_data=
  // API_URL="https://api.brilleightyeight.com/Api_Office/index.php?db_akses=1&akses_token=77887788&Store_Code=B8801";
  constructor(private httpsClient: HttpClient) { }
  getUser(){
    return this.httpsClient.get(this.API_URL);
  }
}